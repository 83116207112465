import React from 'react'
import { Container, Row, Col, Card, Badge } from 'react-bootstrap'
import moment from 'moment'
import Parser from 'html-react-parser'
const formatBlogs = (blog) => {
    return {
        subtitle: blog && blog.title,
        createdAt: blog && blog.createdAt,
        image: blog && blog.media.file.url,
        tag: blog && blog.tag,
        url:blog && blog.url,
        blogId: blog && blog.contentful_id,
        description: blog && blog.description.childContentfulRichText.html
    }
}
const fetchRecentBlogs = (blogs) => {
    var sortedBlogs = blogs.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt)
    })
    var latestBlog = sortedBlogs.slice(0, 4)
    return latestBlog && latestBlog.map(blog => {
        return {
            subtitle: blog && blog.title,
            createdAt: blog && blog.createdAt,
            image: blog && blog.media.file.url,
            tag: blog && blog.tag,
            url:blog && blog.url,
            blogId: blog && blog.contentful_id,
            description: blog && blog.description.childContentfulRichText.html
        }
    })
}

const fetchCategoryBlogs = (blogs, type) => {
    var filteredBlogs = blogs.filter((blog => blog.tag === type))
    return filteredBlogs && filteredBlogs.map(blog => {
        return {
            subtitle: blog && blog.title,
            createdAt: blog && blog.createdAt,
            image: blog && blog.media.file.url,
            tag: blog && blog.tag,
            url:blog && blog.url,
            blogId: blog && blog.contentful_id,
            description: blog && blog.description.childContentfulRichText.html
        }
    })
}

const BlogDetailComponent = ({ blog, data }) => {
    const blogData = blog && formatBlogs(blog[0])
    const recentBlogs = data && fetchRecentBlogs(data)
    const digitalTransformationBlogs = data && fetchCategoryBlogs(data, 'Digital Transformation')
    const businessBlogs = data && fetchCategoryBlogs(data, 'Business')
    const developmentBlogs = data && fetchCategoryBlogs(data, 'Development')
    const testingAutomationBlogs = data && fetchCategoryBlogs(data, 'Testing Automation')
    return (
        <Container fluid>
            <Container className="pb-5">
                <Row className="pt-5">
                    <Col md="8">
                        <Card style={{ borderRadius: 'none' }}>
                            <Card.Img variant="top" src={blogData && blogData.image} />
                            <Card.Body>
                                <h5>
                                    <Badge className="text-uppercase blog-tag">{blogData && blogData.tag}</Badge>
                                </h5>
                                <Card.Title as="h5" className="font-weight-bold">{blogData && blogData.subtitle}</Card.Title>
                                <Card.Title as="p" className="text-standard">Pushed on {blogData && moment(blogData.createdAt).format('MMMM DD, YYYY')}</Card.Title>
                                <div>{blogData && Parser(blogData.description || "")}</div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Container>
                            <Row className="p-1"><h4 className="font-weight-bold">Categories</h4></Row>
                            <Row className="p-1">Business ({businessBlogs.length})</Row>
                            <Row className="p-1">Development ({developmentBlogs.length})</Row>
                            <Row className="p-1">Digital Transformation ({digitalTransformationBlogs.length})</Row>
                            <Row className="p-1">Testing Automation ({testingAutomationBlogs.length})</Row>
                            <Row className="mt-2 p-1"><h4 className="font-weight-bold">Recent Post</h4></Row>
                            {recentBlogs && recentBlogs.map(blog => (
                                <a key={blog.blogId} href={`/knowledgeshare/${blog.url}`} className="text-dark">
                                    <Row className="p-1">
                                        <Col style={{ padding: '0px' }} md="4">
                                            <img width="95px" height="80px" src={blog.image} alt="Blog" />
                                        </Col>
                                        <Col>
                                            <Row><h6 className="font-weight-bold">{blog.subtitle}</h6></Row>
                                            <Row className="text-standard text-uppercase" style={{ fontSize: '14px' }}>{moment(blog.createdAt).format('MMMM DD, YYYY')}</Row>
                                        </Col>
                                    </Row>
                                </a>

                            ))}
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default BlogDetailComponent