import React, { Component } from 'react'
import BannerImage from '../components/elements/BannerImage'
import banner from '../images/slicing/blogs/banner.png'
import { StaticQuery, graphql } from "gatsby"
import BlogDetailComponent from '../components/blogs/BlogDetailComponent'
import { addSubscriberMail } from '../middleware/apis'
class BlogDetailContainer extends Component {
    state = {
        subscribeEmail: ''
    }
    handleSubscribeEmail = (subscribeEmail) => {
        this.setState({
            subscribeEmail
        })
    }
    submitSubscriptionForm = async () => {
        const { subscribeEmail } = this.state
        await addSubscriberMail({ email: subscribeEmail })
            .then((res) => {
                console.log("hello")
            })
            .catch(error => {
                console.log(error)
            })
        this.setState({
            subscribeEmail: ''
        })
    }

    render() {
        const { blog } = this.props
        return (
            <StaticQuery
                query={graphql`
                {
                    allContentfulBlogs {
                      nodes {
                        contentful_id
                        createdAt
                        tag
                        title
                        url
                        description {
                          content {
                            content {
                              value
                            }
                          }
                          childContentfulRichText {
                            html
                          }
                        }
                        media {
                          file {
                            url
                          }
                        }
                      }
                      totalCount
                    }
                  }
          `}
                render={data => {
                    const selectedBlog = blog
                    return (
                        <>
                            <BannerImage
                                activeTab="Blog Details"
                                bannerImage={banner} />
                            <BlogDetailComponent
                                blog={selectedBlog}
                                data={data && data.allContentfulBlogs.nodes}
                            />
                        </>
                    )
                }}
            />
        )
    }
}

export default BlogDetailContainer